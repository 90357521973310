<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Octubre 2023                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="d-flex">
    <SubMenu :nameModule="nameModule" :subMenu="subMenu" />

    <!-- Seccion para cargar el contenido de la navegación del modulo-->
    <v-slide-y-transition mode="out-in">
      <router-view></router-view>
    </v-slide-y-transition>
  </div>
</template>
  
  
  <!-- #################################################################################### -->
  <!-- ###### Sección de Scripts                                                     ###### -->
  <!-- #################################################################################### -->
<script>
import { mapState } from "vuex";
import SubMenu from '@/components/SubMenu.vue';
import { Role } from "@/router/role.js";

export default {
  name: 'Tracking',
  components: { SubMenu },
  data: () => ({
    tab: null,
    nameModule: "Logística",
    userRoles: {},
    roles: Role,
    subMenu: [
      {
        name: "Tracking",
        icon: "pin_drop",
        rol: Role.Logistica_Tracking,
        link: { name: 'modules.logistics.tracking' }
      },

    ],

  }),
  computed: {
    ...mapState(["auth"]),
  },
  created() {
    this.userRoles = this.auth.roles;
  },
}
</script>
  
  <!-- #################################################################################### -->
  <!-- ###### Sección de Styles                                                      ###### -->
  <!-- #################################################################################### -->
<style scoped></style>